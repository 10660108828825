@import "../../styles/variables.scss";

.info-card-wrapper {
  width: 100%;
  margin-bottom: 20px;
  .about-div-margin {
    margin: 10px 0px;
  }
  .info-card {
    .info-card-title {
      background-color: $whiteColor;
      color: #1a1a1a;
      @include Roboto-Bold;
      font-size: 17px;
      // font-weight: 700;
      padding: 13px 17px;
      line-height: 20px;
    }
    .bg-white {
      // background-color: $whiteColor !important;
    }
    .info-card-body {
      background-color: $whiteColor;
      padding: 0px 17px 15px 17px;

      .bio-text {
        color: $textColor;
        @include Roboto-Medium;
        font-size: 15px;
        font-weight: 500;
        // overflow-wrap: break-word;
        // white-space: pre;
      }
      .profile-pictures {
        width: 90px;
        height: 90px;
        background-image: url("../../assets/img/userlist_default_picture.svg");
        background-size: cover;
        display: flex;

        img {
          width: 90px;
          height: auto;
          margin-right: 10px !important;
        }
      }
      .data-container {
        margin-bottom: 15px;
        :last-of-type {
          margin-bottom: 0px !important;
        }
        .sub-title {
          color: $primary;
          @include Roboto-Medium;
          font-size: 15px;
        }
        .sub-title-description {
          color: $textColor;
          @include Roboto-Medium;
          font-size: 15px;
          white-space: pre-line;

        }
      }
    }
  }
}
