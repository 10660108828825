.password-icon{
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
select{
  //text-overflow:unset !important;
  text-overflow: ellipsis;
}