@import "../../styles/variables.scss";

.header-wrapper{
    width: 100%;
    height: 60px;
    background-color: $whiteColor;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px 0px 40px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    .logo-wrapper{
        cursor: pointer;
        width: 175px;
        height: 33px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .menu-toggle{
        display: none;
        color: $whiteColor;
        cursor: pointer;
    }
    .user{ 
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include Roboto-Regular;
        // color: $;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: left;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        .profile-picture{
            width: 36px;
            height: 36px;
            border-radius: 50%;
            margin-left: 10px;
            background-image: url(../../assets/img/userlist_default_picture.svg);
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}

@media screen and (max-width: 1024px){
    .menu-toggle{
        display: block !important;
        background-image: url('../../assets/img/hamburger_white.svg');
        width: 20px;
        height: 15px;
    }
    .logo-wrapper{
        display: none;
    }
}