.card {
  padding: 50px 45px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  background: #fff;
  max-width: 550px !important;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;

}