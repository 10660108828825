@import "../../styles//variables.scss";

.role-bar {
  background-color: $bar-color;
  height: 50px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 17px 20px;
  span {
    font-size: 14px;
    color: $textColor2;
    @include Roboto-Medium;
  }
}
.common-wrapper-setting {
  overflow-y: unset !important;
  position: fixed;
  background-color: $page-bg;
  .table-conatiner {
    margin-top: 0px !important;
    .name-col {
      span {
        font-size: 14px;
        color: $textColor2;
        @include Roboto-Medium;
        margin-left: 10px;
      }
    }
    
    .roleBtn {
        justify-content:flex-end !important; 
        margin-right: 18px;
    }
 .table tr{
  background-color: $whiteColor;
  cursor: unset !important;

    }
  }
  .role-input-container {
    background-color: $whiteColor;
    display: flex;
    padding: 10px 20px;

    .input-container {
      flex: 5;
      padding: 0 !important;
      // padding-bottom: 0;
      margin-right: 10px;
      .error {
        position: unset !important;
        padding-left: 2px;
      }
      .has-float-label input {
        border: 1px solid #dbdbdb !important;
       
      }
    }
    button {
      width: 150px;
      background-color: $primary;
      color: $whiteColor;
    }
    .input-container-dropdown {
      flex: 1.5;
      margin-right: 10px;
      height: 40px;
      padding-bottom: 20px;
  
      .form-select{
        height: 40px;
        border: 1px solid #808080;
        @include Roboto-Regular;
        font-size: 14px;
      }
    }
  }
}
